var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('div',{staticClass:"d-flex justify-end"},[_c('v-btn',{attrs:{"color":"primary","to":{ name: 'Question.Create' }}},[_vm._v("New Question")])],1),_c('v-row',{staticClass:"mb-2"},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('h1',[_vm._v("Question")])]),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"prepend-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.options.search),callback:function ($$v) {_vm.$set(_vm.options, "search", $$v)},expression:"options.search"}})],1)],1),_c('data-table',{attrs:{"headers":_vm.headers,"items":_vm.questions,"options":_vm.options,"server-items-length":_vm.totalData,"loading":_vm.loading},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.questionPackName",fn:function(ref){
var questionPackName = ref.item.questionPackName;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"question-pack",on:{"click":function($event){_vm.options.search = questionPackName;
              _vm.options.page = 1;}}},'span',attrs,false),on),[_vm._v(_vm._s(questionPackName))])]}}],null,true)},[_c('span',[_vm._v("Filter this question pack")])])]}},{key:"item.question",fn:function(ref){
              var question = ref.item.question;
return [_c('span',[_vm._v(_vm._s(_vm._f("ellipsis")(_vm._f("stripHtml")(question))))])]}},{key:"item.createdAt",fn:function(ref){
              var createdAt = ref.item.createdAt;
return [_c('span',[_vm._v(_vm._s(_vm._f("parseStringDate")(createdAt)))])]}},{key:"item.action",fn:function(ref){
              var id = ref.item.id;
return [_c('div',{staticStyle:{"width":"100px"}},[_c('v-btn',{staticClass:"mt-1 mb-2",attrs:{"color":"warning","to":{ name: 'Question.Edit', params: { id: id } },"medium":"","block":""}},[_vm._v("Edit")]),_c('v-btn',{staticClass:"mb-1",attrs:{"color":"error","block":""},on:{"click":function($event){return _vm.deletePrompt(id)}}},[_vm._v("Delete")])],1)]}}],null,true)}),_c('v-dialog',{attrs:{"max-width":"290"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v(" Delete selected data? ")]),_c('v-card-text',[_vm._v(" This action is irreversible. Deleting this data means other data that containing this data will also be deleted. ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"error darken-1","loading":_vm.loadingDelete,"text":""},on:{"click":function($event){return _vm.deleteData()}}},[_vm._v(" Delete ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }