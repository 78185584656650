<template>
  <v-container>
    <div class="d-flex justify-end">
      <v-btn color="primary" :to="{ name: 'Question.Create' }"
        >New Question</v-btn
      >
    </div>
    <v-row class="mb-2">
      <v-col cols="12" md="6">
        <h1>Question</h1>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          v-model="options.search"
          prepend-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-col>
    </v-row>
    <data-table
      :headers="headers"
      :items="questions"
      :options.sync="options"
      :server-items-length="totalData"
      :loading="loading"
    >
      <template
        v-slot:[`item.questionPackName`]="{ item: { questionPackName } }"
      >
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <span
              v-bind="attrs"
              v-on="on"
              @click="
                options.search = questionPackName;
                options.page = 1;
              "
              class="question-pack"
              >{{ questionPackName }}</span
            >
          </template>
          <span>Filter this question pack</span>
        </v-tooltip>
      </template>
      <template v-slot:[`item.question`]="{ item: { question } }">
        <span>{{ question | stripHtml | ellipsis }}</span>
      </template>
      <template v-slot:[`item.createdAt`]="{ item: { createdAt } }">
        <span>{{ createdAt | parseStringDate }}</span>
      </template>
      <template v-slot:[`item.action`]="{ item: { id } }">
        <div style="width: 100px">
          <v-btn
            color="warning"
            :to="{ name: 'Question.Edit', params: { id } }"
            class="mt-1 mb-2"
            medium
            block
            >Edit</v-btn
          >
          <v-btn color="error" class="mb-1" @click="deletePrompt(id)" block
            >Delete</v-btn
          >
        </div>
      </template>
    </data-table>
    <v-dialog v-model="dialog" max-width="290">
      <v-card>
        <v-card-title class="headline"> Delete selected data? </v-card-title>

        <v-card-text>
          This action is irreversible. Deleting this data means other data that
          containing this data will also be deleted.
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="error darken-1"
            @click="deleteData()"
            :loading="loadingDelete"
            text
          >
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<style scoped>
.question-pack:hover {
  cursor: pointer;
}
</style>

<script>
import format from "date-fns/format";
import { mapActions } from "vuex";
import Question from "@/services/question";
import DataTable from "@/components/DataTable.js";

export default {
  name: "Question.Index",
  components: {
    DataTable
  },
  data() {
    return {
      totalData: 0,
      questions: [],
      loading: true,
      loadingDelete: false,
      options: {
        search: undefined
      },
      headers: [
        { text: "Position", value: "position", width: "100px" },
        { text: "Question Pack", value: "questionPackName", width: "130px" },
        { text: "Question", value: "question" },
        { text: "Created At", value: "createdAt", width: "145px" },
        { text: "Action", value: "action", sortable: false }
      ],
      dialog: false,
      selectedId: ""
    };
  },
  watch: {
    options: {
      handler() {
        this.getQuestions();
      },
      deep: true
    }
  },
  filters: {
    ellipsis(questionText) {
      return questionText.length > 100
        ? `${questionText.substring(0, 100)}...`
        : questionText;
    },
    stripHtml(html) {
      const div = document.createElement("div");
      div.innerHTML = html;
      const text = div.textContent || div.innerText || "";
      return text;
    },
    parseStringDate(date) {
      return format(new Date(date), "yyyy-MM-dd HH:mm");
    }
  },
  created() {
    this.setNavbarTitle("Manage Question");
  },
  methods: {
    ...mapActions("drawer", ["setNavbarTitle"]),
    async getQuestions() {
      this.loading = true;
      this.questions = [];
      const questions = await Question.index(this.options);
      this.questions = questions.data.data.questions;
      this.totalData = questions.data.data.total_data;
      this.loading = false;
    },
    async deletePrompt(id) {
      this.selectedId = id;
      this.dialog = true;
    },
    async deleteData() {
      try {
        this.loadingDelete = true;
        await Question.delete(this.selectedId);
        this.$router.go(this.$router.currentRoute);
      } catch (error) {
        this.loadingDelete = false;
      }
    }
  }
};
</script>
